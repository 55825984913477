import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export const ComboImage = (
	<StaticQuery
		query={graphql`
			query comboImage {
				file(relativePath: { eq: "combo-image.png" }) {
					childImageSharp {
						fluid(maxWidth: 475) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		`}
	>
		{(data) => (
			<Img
				fluid={data.file.childImageSharp.fluid}
				className="intro__image"
				alt="Tearribles Combo"
			/>
		)}
	</StaticQuery>
);
