import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Container from "../../components/Container/container";
import ProductSelect from "../../components/ProductSelect/product-select";
import { tearribleStyles } from "../../types";
import { ComboImage } from "../../components/combo-image";
import christmasDesktopBackground from "../../assets/christmas-banner-desktop.png";
import christmasMobileBackground from "../../assets/christmas-banner-mobile.png";
import CTA from "../../components/CTA/cta";

const winterTearrorDescrption = (
	<>
		<p>
			What's the best way to use Black Friday sales? Well, stocking up for
			Christmas of course!
			<br />
			We have you covered in the VIP-Exclusive pre-sale of Christmas
			Tearrors.
		</p>
	</>
);

class LimitedEditionSelect extends Component {
	render() {
		const classicData =
			this.props.data.allShopifyCollection.edges[0]?.node.products.filter(
				(node) => {
					return node.tags.includes("Classic");
				},
			);

		const christmasData =
			this.props.data.allShopifyCollection.edges[0]?.node.products.filter(
				(node) => {
					return node.tags.includes("Christmas");
				},
			);

		const fireHoseData =
			this.props.data.allShopifyCollection.edges[0]?.node.products.filter(
				(node) => {
					return node.tags.includes("Fire Hose");
				},
			);

		return (
			<>
				<img
					src={christmasDesktopBackground}
					alt="Christmas Tearrors"
					style={{ width: "100%" }}
					className="product-select__christmas-desktop"
				/>
				<img
					src={christmasMobileBackground}
					alt="Christmas Tearrors"
					style={{ width: "100%" }}
					className="product-select__christmas-mobile"
				/>
				<Container
					style={{ paddingLeft: "2.4rem", paddingRight: "2.4rem" }}
				>
					<h2
						style={{
							marginBottom: "3.6rem",
							fontSize: "2.4rem",
						}}
					>
						Dash into the twisted wonderland of Christmas Tearrors
					</h2>
					<div
						className="intro__wrapper"
						style={{
							alignItems: "center",
							justifyContent: "center",
							gap: "3.6rem",
						}}
					>
						<Img
							fluid={this.props.data.file.childImageSharp.fluid}
							className="intro__image"
							alt="Christmas Tearribles"
							style={{
								maxWidth: "360px",
								marginBottom: "2.4rem",
							}}
						/>
						<div className="intro__text">
							<p>
								This festive season, spend quality time bonding
								with your pup as you let them unleash their
								natural instincts on our Limited Edition
								collection of Christmas icons. Whether you have
								a Doberman trapped in a Chihuahua's body, or an
								actual Doberman, we've got the perfect size
								monster to let the Christmas mayhem begin!
							</p>
						</div>
					</div>
				</Container>
				<ProductSelect
					title="Christmas Tearrors"
					description={winterTearrorDescrption}
					{...this.props}
					sort={(a, b) => {
						if (!a.size || !b.size) return 0;

						const aSize = tearribleStyles[a.size]?.index;
						const bSize = tearribleStyles[b.size]?.index;

						if (aSize < bSize) return -1;
						if (aSize > bSize) return 1;
						return 0;
					}}
					data={{
						allShopifyCollection: {
							edges: [
								{
									node: {
										products: christmasData,
									},
								},
							],
						},
					}}
				/>
				<ProductSelect
					{...this.props}
					title="Christmas in Florida Tearribles"
					sort={(a, b) => {
						if (!a.size || !b.size) return 0;

						const aSize = tearribleStyles[a.size]?.index;
						const bSize = tearribleStyles[b.size]?.index;

						if (aSize < bSize) return -1;
						if (aSize > bSize) return 1;
						return 0;
					}}
					data={{
						allShopifyCollection: {
							edges: [
								{
									node: {
										products: fireHoseData,
									},
								},
							],
						},
					}}
				/>
				<div
					className="details detail-no-grey"
					style={{ color: "#FFFFFF" }}
				>
					<Container>
						<div
							className="intro__wrapper"
							style={{
								alignItems: "center",
								justifyContent: "center",
								gap: "3.6rem",
							}}
						>
							<div className="intro__text">
								<h3 style={{ color: "white" }}>
									Explore our Exclusive Combos
								</h3>
								<p style={{ color: "white" }}>
									Indecisive moments meet their match with our
									combos! Dive into a world of perfectly
									paired toys and delights, curated for every
									taste and size. No need to choose - we've
									crafted the ultimate combinations to satisfy
									every craving. Explore our toys, treats, and
									chews, and let the combo magic unfold!
								</p>
							</div>
							{ComboImage}
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "3.6rem",
							}}
						>
							<CTA to="/products/combos" title="Shop Combos" />
						</div>
					</Container>
				</div>
				<ProductSelect
					title="Here for the Classics?"
					{...this.props}
					data={{
						allShopifyCollection: {
							edges: [
								{
									node: {
										products: classicData,
									},
								},
							],
						},
					}}
				/>
			</>
		);
	}
}

export default (props) => (
	<StaticQuery
		query={graphql`
			query limitedEditionQuery {
				allShopifyCollection(filter: { handle: { eq: "vip" } }) {
					edges {
						node {
							title
							handle
							products {
								id
								shopifyId
								legacyResourceId
								title
								descriptionHtml
								productType
								tags
								handle
								collections {
									title
								}
								featuredImage {
									originalSrc
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid_withWebp_noBase64
											}
										}
									}
								}
								variants {
									shopifyId
									legacyResourceId
									title
									id
									sku
									price
									presentmentPrices {
										price {
											amount
											currencyCode
										}
									}
									compareAtPrice
									availableForSale
								}
							}
						}
					}
				}
				file(relativePath: { eq: "christmas-dog.png" }) {
					childImageSharp {
						fluid(maxWidth: 475) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		`}
	>
		{(data) => <LimitedEditionSelect data={data} {...props} />}
	</StaticQuery>
);
